import React, { useRef } from 'react';
import { FormControlLabel, FormGroup, Switch, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles'
import { Rainbow } from "./rainbow-core";
import { RainbowAudioService } from './rainbow-media'
import {
    Box,
    Card,
    CardActions,
    CardContent,
    IconButton
} from "@mui/material";
import { VolumeMute, VolumeOff, Videocam, VideocamOff, PhonelinkOff, PhoneDisabled, TextRotationNoneTwoTone, MotionPhotosAutoTwoTone } from "@mui/icons-material";
import { rainbowService } from "../index";
import { hystogramLine } from '../services/audioDetection';
//import { fontSize } from '@mui/system';
//import Marquee from 'react-fast-marquee';

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '95%',
            height: '100%'

            //maxWidth: 360,
            //maxHeight: 250,
            //overflow: 'auto',
            //backgroundColor: 'transparent'//theme.palette.background.paper,
        },
        card: {

        },
        cardActions: {
            backgroundColor: 'lightgray',
            height: '30px',
        },
        video: {
            width: '250px',
            //height: '100%'
        }
    }),
);

type AppProps = {
    videoId: string
    disabled?: boolean
    stText?: string
};

var onCallStatusChange;

export default function RainbowVideoCard(props: AppProps) {
    const classes = useStyles();
    const [call, setCall] = React.useState<Rainbow.ICall>();
    const [hasActiveCall, setHasActiveCall] = React.useState<boolean>(false);
    const [doTranscribe, setDoTranscribe] = React.useState<boolean>(false)
    const [stText, setSTText] = React.useState<string>("Speech-to-Text")
    const [remoteVolume, setRemoteVolume] = React.useState<number>(0);
    var rainbowAudioService = useRef<RainbowAudioService>();


    React.useEffect(() => {
        console.log(`[RAINBOW-VIDEO-CARD] :: init of RainbowVideoCard ${props.videoId}`)
        setSTText(props.stText)
        rainbowAudioService.current = new RainbowAudioService();
        rainbowAudioService.current.onTTSresponseReceived.subscribe(data => {
            setSTText(data.data)
        })
        rainbowAudioService.current.onMediaData.subscribe(data=>{setRemoteVolume(data.volume);
            //console.log(`[RAINBOW-VIDEO-CARD] :: ${hystogramLine(data.volume)}`, data)
        })
        setCall(new Rainbow.ICall(Rainbow.IWebRTC.activeCall))
        setHasActiveCall(Rainbow.IWebRTC.hasActiveCall)
        rainbowService.webRTC.onCallStatusChange
            .subscribe(status => {
                console.log(`[RAINBOW-VIDEO-CARD] :: onCallStatusChanged`, rainbowService.webRTC)
                if (status == Rainbow.Call.Status.ACTIVE && call && call['remoteMedia'] == 1) {
                    console.log(`[RAINBOW-VIDEO-CARD] :: clearing video element`);
                    (async () => {
                        const video = document.querySelector(props.videoId);
                        //video.srcObject = null;
                        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                        const new_stream = new MediaStream(stream.getVideoTracks());
                        video['srcObject'] = new_stream;
                    })()
                }
                setCall(new Rainbow.ICall(Rainbow.IWebRTC.activeCall))
                setHasActiveCall(Rainbow.IWebRTC.hasActiveCall)
            })
        rainbowService.webRTC.onStreamAdded
            .subscribe(data => {

            })
        return () => {
            console.log(`[RAINBOW-VIDEO-CARD] :: closing RainbowVideoCard ${props.videoId}`)
            rainbowAudioService.current.audioSocketService.disconnect()
            //onCallStatusChange.unsubscribe();
        }
    }, [])

    React.useEffect(() => {
        console.log(`[RAINBOW-VIDEO-CARD] :: sTText changed`)
        setSTText(props.stText)
    }, [props.stText])


    const transcribe = async (event) => { let res = await rainbowAudioService.current.onTranscriptionToggleChange(!doTranscribe); if (res) setDoTranscribe(!doTranscribe); console.log(`[RAINBOW-VIDEO-CARD] :: enable transcribe result: ${res}`, doTranscribe, event) }



    return (<div className={classes.root}>
        <Card>
            <CardContent>
                <Box  height={hasActiveCall && call && call['remoteMedia'] == 3 ? '100%' : '5px'} className={classes.video}>
                    <video className={classes.video} id={props.videoId} autoPlay />
                </Box>
                {doTranscribe &&
                    <Box marginLeft={'-16px'} width={'300px'} height={'100px'} bgcolor={'lightseagreen'}>
                        {<Typography sx={{ wordBreak: "break-word",fontSize:'12px' }}>{stText}</Typography>}
                        {/*<Marquee>{stText}</Marquee>*/}
                    </Box>}
                    {doTranscribe &&<Box height={'10px'}>
                    <Typography width={'200px'} sx={{ wordBreak: "break-word" }}>{`Volume::${hystogramLine(remoteVolume/15)}`}</Typography>
                    </Box>}
            </CardContent>
            <CardActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'stretch' }} className={classes.cardActions} >
                <IconButton disabled={!hasActiveCall} color="secondary"
                    onClick={() => { call.isMuted ? call.unmute() : call.mute() }}>{hasActiveCall && call.isMuted ? <VolumeMute /> : <VolumeOff />}</IconButton>
                <IconButton color="secondary" disabled={!hasActiveCall}
                    onClick={() => { rainbowService.sdk.webRTC.hideRemoteVideo(call.id) }}>{hasActiveCall && call.isRemoteVideoMuted ? <VideocamOff /> : <Videocam />}</IconButton>
                <IconButton color="secondary" disabled={!hasActiveCall}
                    onClick={() => { call.release() }}>{<PhoneDisabled />}</IconButton>
                {/*<FormGroup>
                    <FormControlLabel control={<Switch checked={doTranscribe} onChange={transcribe} name='transcribe' color="secondary" />} label="Transcribe" />
                </FormGroup>*/}
            </CardActions>
        </Card>
    </div>);
}
