import React from 'react';
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, useTheme } from '@mui/material/styles';
import { Rainbow } from "../rainbow/rainbow-core";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Avatar,
    Container,
    IconButton,
    List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, Typography, Select, OutlinedInput, MenuItem, SelectChangeEvent, InputLabel,
} from "@mui/material";
import { rainbowService } from "../index";
import RainbowVideoCard from "./rainbow-video-card";
import { take } from "rxjs";
import { CallTwoTone, CenterFocusStrongTwoTone, ShortTextTwoTone, VideoCallTwoTone, ViewHeadlineTwoTone } from '@mui/icons-material';
import { AudioEvent, IAudioEventData, dB, hystogramLine } from '../services/audioDetection';

const useStyles: any = makeStyles(theme => createStyles({
    root: {
        width: '100%',

        //maxWidth: 360,
        //maxHeight: 250,
        //overflow: 'auto',
        //backgroundColor: 'transparent'//theme.palette.background.paper,
    },
    icons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end'
    },
    card: {
        width: '300px',
        zIndex: 1000
    },
    cardActions: {
        backgroundColor: 'lightgray',
        height: '30px'
    },
    video: {
        width: 'auto',
        height: '200px'
    },
    item: {
        backgroundColor: 'lightgray',
        margin: '0px'
    },
    border: {
        borderColor: 'darkgray',
        border: "1px solid black"

    }
}),
);

type AppProps = {
    contacts: Rainbow.Contact[],
    disabled?: boolean
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(name: string, mediaDevices: string[], theme: Theme) {
    return {
        fontWeight:
            mediaDevices.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function CallCard(props: AppProps) {
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setanchorEl] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [checked, setChecked] = React.useState<number[]>([]);
    const [contacts, setContacts] = React.useState<Rainbow.Contact[]>([]);
    const [hasActiveCall, setHasActiveCall] = React.useState<boolean>(false);
    //const [audioEventData, setAudioEventData] = React.useState<Map<string, IAudioEventData>>(new Map());
    const [lastEvent, setLastEvent] = React.useState<AudioEvent>();
    const [signal, setSignal] = React.useState<string>();
    const [lastEventData, setLastEventData] = React.useState<IAudioEventData>();
    const [audioIn, setAudioIn] = React.useState<number>(0);
    const [audioOut, setAudioOut] = React.useState<number>(0);
    const [videoIn, setVideoIn] = React.useState<number>(0);
    const [mediaDevices, setMediaDevices] = React.useState<string[]>([]);


    React.useEffect(() => {
        setContacts(props.contacts)
        setHasActiveCall(Rainbow.IWebRTC.hasActiveCall)
        addAudioEventListeners()
        console.log(`[CALLCARD] :: opening CallCard`, props)
        rainbowService.webRTC.onCallStatusChange
            .subscribe(status => {
                console.log(`[RAINBOW-VIDEO-CARD] :: onCallStatusChanged`, rainbowService.webRTC)
                setHasActiveCall(Rainbow.IWebRTC.hasActiveCall)
            })
        return () => {
            console.log(`[CALLCARD] :: closing CallCard`)
            removeAudioEventListeners()
        }

    }, [])


    const addAudioEventListeners = () => {
        for (let ev of Object.values(AudioEvent)) {
            console.log(`[CallCard] :: Setting audio event listener for ${ev}`)
            document.addEventListener(ev, event => audioEventHandler(ev, event)); //setAudioEventData(audioEventData.set(ev, event as unknown as IAudioEventData))
        }
    }
    const removeAudioEventListeners = () => {
        for (let ev of Object.values(AudioEvent)) {
            console.log(`[CallCard] :: Removing audio event listener for ${ev}`)
            document.removeEventListener(ev, event => audioEventHandler(ev, event));
        }
    }


    const audioEventHandler = (ev: AudioEvent, event) => {
        if (true || (lastEventData && lastEventData.detail.volume != event.detail.volume) || (lastEvent && ev != lastEvent)) {
            console.log(`[CallCard] :: received audio event with volume change or status change for ${ev} (${lastEventData ? lastEventData.detail.volume : 'unknown'}/${event.detail.volume})`, event);
        } else {
            //setLastEvent(ev);
            //setLastEventData(event);
        }
        switch (ev) {
            case 'signal': {
                const volume = event.detail.volume.toFixed(9)
                const timestamp = event.detail.timestamp
                const items = event.detail.items.toString().padEnd(3)
                const dBV = dB(event.detail.volume)
                const line = hystogramLine(volume)
                setSignal(line)
                console.log(`signal  ${timestamp} ${items} ${volume} ${dBV} ${line}`)
                break;
            }
            case 'silence':
                const volume = event.detail.volume.toFixed(9)
                const timestamp = event.detail.timestamp
                const items = event.detail.items.toString().padEnd(3)
                const dBV = dB(event.detail.volume)
                console.log(`silence ${timestamp} ${items} ${volume} ${dBV}`)
                break;
        }
        setLastEvent(ev); setLastEventData(event);
    }

    /*React.useEffect(() => {
        //console.log(`[CallCard] :: contact changed`, props.contacts)
        setSelectedIndex(-1)
    }, [props.contacts])*/

    const onCall = (event) => {
        //console.log(`[CallCard] :: onCall clicked`, event, props)
        setanchorEl(event.currentTarget);
        setOpen(!open)
    };

    const startVideo = () => {
        if (selectedIndex == -1) {
            //console.log(`[CALL-CARD] :: no selection to start video`)
            return
        }
        if (rainbowService.webRTC.callActive) return;
        //console.log(`[CALL-CARD] :: Start video call to ${props.contacts[selectedIndex]['dbId']}`, rainbowService.sdk.webRTC.canMakeAudioVideoCall())
        rainbowService.sdk.webRTC.callInVideo(props.contacts[selectedIndex]['id']).then(() => {
            console.warn(`[CallCard] :: video started`);
            let s = rainbowService.webRTC.onCallActive.asObservable().pipe(take(1)).subscribe(() => {
                let idx = selectedIndex;
                setSelectedIndex(-1)
                setTimeout(() => { setSelectedIndex(idx) }, 1000)
                s.unsubscribe()
            })
        })
    };
    const startAudio = () => {
        if (selectedIndex == -1) {
            //console.log(`[CALL-CARD] :: no selection to start video`)
            return
        }
        //console.log(`[CALL-CARD] :: Start audio call to ${props.contacts[selectedIndex]['dbId']}`, rainbowService.sdk.webRTC.canMakeAudioVideoCall());
        //rainbowService.sdk.webRTC.callInAudio(props.contacts[selectedIndex]['dbId'])
        let c = (new Rainbow.IContact(props.contacts[selectedIndex]));
        c.call(false)
    };

    const handleChange = (event: SelectChangeEvent<typeof mediaDevices>) => {
        console.log(`change`, event)
        const {
            target: { value },
        } = event;
        setMediaDevices(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        rainbowService.setMultimediaDevices(Number(value[0]), Number(value[1]) - 100 - rainbowService.audioInputDevices.length || 0)
    };

    const handleToggle = (value: number) => {
        const currentIndex = checked.indexOf(value as never);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value as never);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (<div className={classes.root}>

        <Container >
            <Card >
                <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'darkgray' }}>
                    <Select
                        //sx={{backgroundColor:'white',width:'200px'}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        label="Audio devices"
                        //placeholder='Multi-Select your IN/OUT media devices'
                        multiple
                        value={mediaDevices} //.map(c=>c==','?'\n':',')
                        onChange={handleChange}
                        input={
                            <OutlinedInput fullWidth={true} multiline={true} minRows={2} maxRows={2} id={"input-for-audio"}  sx={{ fontSize: '10px', maxWidth:'300px' }}  />
                        }
                        MenuProps={MenuProps}
                    >
                        {([] as MediaDeviceInfo[]).concat(rainbowService.audioInputDevices, rainbowService.audioOutputDevices).map((device, idx) => (
                            <MenuItem sx={{ fontSize: '12px' }}
                                key={`${device.kind == 'audioinput' ? 'IN' : 'OUT'}::${device.label}-${device.deviceId}`}
                                value={device.kind == 'audiooutput' ? 100 + idx : idx}
                                style={getStyles(device.label, mediaDevices, theme)}
                            >
                                {`${device.kind == 'audioinput' ? 'IN' : 'OUT'}::${device.label}`}
                            </MenuItem>
                        ))}
                    </Select>
                    {contacts.length > 0
                        ? <Box key={'rbcontacts'} sx={{ backgroundColor: 'darkgray', width: '100%' }}>
                            <List dense>
                                {
                                    contacts.map((contact, idx) => {
                                        const labelId = `checkbox-list-secondary-label-${contact.id}`;
                                        return (
                                            <ListItem key={contact.id} button selected={selectedIndex == idx}
                                                className={selectedIndex != idx ? classes.item : `${classes.item} ${classes.border}`}>
                                                <ListItemAvatar>
                                                    <div>
                                                        <Avatar alt={`Avatar ${contact.id}`}
                                                        >
                                                            <div style={{ fontSize: '10px' }}>{contact.initials}</div>
                                                        </Avatar>
                                                    </div>
                                                </ListItemAvatar>
                                                <ListItemText id={labelId} primary={`${contact['fullname']}`}
                                                    secondary={(checked.indexOf(idx) !== -1) ? `${contact.status}` : ``} />
                                                <ListItemSecondaryAction className={classes.icons}>
                                                    <IconButton edge='end' style={{ alignItems: 'flex-start' }}
                                                        onClick={() => {
                                                            console.log(`clicked`);
                                                            handleToggle(idx)
                                                        }}
                                                    >{(checked.indexOf(idx) !== -1) ? <ShortTextTwoTone /> : <ViewHeadlineTwoTone />}
                                                    </IconButton>
                                                    <IconButton color={selectedIndex == idx ? 'secondary' : 'inherit'} edge='end' disabled={contact.status != 'online' || contact.loginEmail == rainbowService.me.loginEmail}
                                                        onClick={() => {
                                                            selectedIndex !== idx ? setSelectedIndex(idx) : setSelectedIndex(-1);
                                                        }}
                                                    ><CenterFocusStrongTwoTone />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })
                                }
                            </List>
                        </Box>
                        : <Box>No Contacts
                        </Box>
                    }
                    <Box marginTop={'5px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <RainbowVideoCard videoId={'largevideo'} />
                    </Box>
                </CardContent>
                <CardActions className={classes.cardActions} >
                    <IconButton disabled={selectedIndex == -1 || hasActiveCall} color="secondary"
                        onClick={() => { startAudio() }}><CallTwoTone /></IconButton>
                    <IconButton color="secondary" disabled={selectedIndex == -1 || hasActiveCall}
                        onClick={() => { startVideo() }}><VideoCallTwoTone /></IconButton>
                    {lastEventData && <Typography>{lastEvent || 'unknown'}/{signal}</Typography>}
                </CardActions>
            </Card>
        </Container>
    </div>);
}
