import * as React from 'react';
import Marquee from "react-fast-marquee";
import { rainbowService } from '../..';
import { Rainbow } from '../../rainbow/rainbow-core';
import { Avatar, Box,CardHeader, Chip, IconButton, Popper, Step, StepButton, StepContent, Stepper, Typography } from '@mui/material';
import { MenuOutlined } from '@mui/icons-material';
import { enqueueSnackbar as toast } from 'notistack';
import CallCard from '../../rainbow/call-card';

export function DefaultMessageTicker(props) {
    let { conversationId } = props;
    const [doPlay, setDoPlay] = React.useState(true);
    const [messages, setMessages] = React.useState<any[]>([
        { contact: { avatar: null, fullname: "Admin" }, bubbleName: "", side: "R", data: "Login to Rainbow to see your messages arrive ..." },
        //{ contact: { avatar: null, fullname: "Pieter Jansens" }, bubbleName: "Test Bubble", side: "R", data: "Hi there" }
    ]);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const [activeIdx, setActiveIdx] = React.useState(0);

    React.useEffect(() => {
        Rainbow.IMessages.ONRECEIVED.subscribe(data => {
            console.log(`[TICKER] :: Received new message`, data);
            let { message, conversation } = data;
            (async () => {
                toast(`${message.data}`, { variant: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
                message.bubbleName = conversation['bubbleName'] ? conversation['bubbleName'] : "";
                message.contact = await rainbowService.sdk.contacts.searchById(conversation.contactId);
                messages.push(...[(message as Rainbow.Message[])].filter((msg: any) => msg.side != 'ADMIN'));
                setMessages(messages);
                setDoPlay(false);
                await new Promise(resolve => setTimeout(resolve, 100));
                setDoPlay(true);
            })();

        })
        rainbowService.onStarted.subscribe(started => {
            if (!started) {
                setMessages([]);
                setDoPlay(false);
                return
            }
            messages[0] = { contact: { avatar: null, fullname: "START" }, bubbleName: "", side: "R", data: "Messages loaded" };
            if (conversationId) {
                getMessages(conversationId).then(res => {
                    setMessages(res);
                })
            } else if (rainbowService.state.started) {
                (async () => {
                    let conversations: Rainbow.Conversation[] = await rainbowService.sdk.conversations.getAllConversations();
                    console.log(`[TICKER] :: Conversations`, conversations);
                    let msgs: Rainbow.Message[] = messages;
                    try {
                        for (let conversation of conversations) {
                            console.log(`[TICKER] :: getting messages for conv`, conversation)
                            let res: Rainbow.Conversation;
                            if (conversation.lastMessage == "") break;
                            res = await rainbowService.sdk.im.getMessagesFromConversation(conversation.id, 1);
                            console.log(`[TICKER] :: got messages:`, res)
                            if (res) {
                                for (let m of res.messages) {
                                    m.bubbleName = res['bubbleName'] ? res['bubbleName'] : "";
                                    m.contact = await rainbowService.sdk.contacts.searchById(m.senderId);
                                }
                                messages.push(...(res.messages as Rainbow.Message[]).filter(msg => msg.side != 'ADMIN'));
                                console.log(`[TICKER] :: Added ${res.messages.length} messages, total is ${msgs.length}`, res)
                                setMessages(messages);
                                setDoPlay(false);
                                await new Promise(resolve => setTimeout(resolve, 100));
                                setDoPlay(true);
                            }
                        }
                    } catch (err) { console.error(`[TICKER] :: got error`, err) }
                    setMessages(messages);
                    await new Promise(resolve => setTimeout(resolve, 100));
                    setDoPlay(false);
                    await new Promise(resolve => setTimeout(resolve, 100));
                    setDoPlay(true);
                    console.log(`[TICKER] :: Loaded ${messages.length} messages`, messages)
                })();
            } else {
                console.warn(`[TICKER] :: Rainbow state is not started, skipping load messages`)
            }
        })
        /*setTimeout(() => {
            let m = messages.push({ contact: { avatar: null, fullname: "Fred" }, bubbleName: "Test Bubble", side: "R", data: "Look here" }            )
            setMessages(messages);
            setTimeout(() => {
                (async () => {
                    
                        setDoPlay(false);
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        setDoPlay(true);
                    
                })();
            }, 1000);
            console.log(`[TICKER] :: Added message`)
        }, 10000);*/
        const getMessages = async (cId, num?) => { return await rainbowService.sdk.im.getMessagesFromConversation(cId, num ? num : 10) }

    }, [conversationId])
    return (
        <div>
            <Marquee play={doPlay} pauseOnHover={true} >
                {messages.map((msg: Rainbow.Message, idx) => {
                    return (
                        <Chip
                            sx={{ maxWidth: "100%", margin: '2px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                            key={`ticker${idx}`} color={`${msg.side == 'L' ? "primary" : "secondary"}`}
                            label={
                                <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
                                    <CardHeader sx={{ maxWidth: '100%' }} disableTypography={true}
                                        title={<Box display='flex' flexDirection={'row'} alignItems={'center'}>
                                            <Typography
                                                variant="h3"
                                                color="white"
                                                textAlign="left"
                                                fontSize="15px"
                                                mt='2px'
                                                mb='-2px'
                                            >{msg['contact'].fullname}
                                            </Typography>
                                            <Typography
                                                variant="h3"
                                                color="white"
                                                textAlign="left"
                                                fontSize="11px"
                                                noWrap={true}
                                                maxWidth={{ lg: '200px', xs: '100px' }}
                                                ml="5px"
                                                mt='4px'
                                                mb='-2px'
                                            >{msg['bubbleName'] ? `(In  '${msg['bubbleName']}')` : ""}</Typography></Box>}
                                        subheader={
                                            <Typography
                                                //variant="caption"
                                                color="white"
                                                textAlign="left"
                                                fontSize={{ lg: "13px", xs: "10px" }}
                                                fontStyle='italic'
                                                maxWidth={{ lg: '400px', xs: '200px' }}
                                                noWrap={true}
                                            //style={{ whiteSpace: "nowrap", wordBreak: "break-all", overflow: 'hidden', textOverflow: 'ellipsis' }}
                                            >{msg.data}</Typography>
                                        }
                                        action={<IconButton aria-describedby={id} onClick={(event) => { console.log(`[TICKER] :: clicked menu`); setDoPlay(!doPlay); setActiveIdx(idx); setAnchorEl(anchorEl ? null : event.currentTarget); }} sx={{ marginRight: '0px' }}><MenuOutlined sx={{ color: 'white' }} /></IconButton>}>{msg.data}
                                    </CardHeader>

                                </Box>
                            }
                            avatar={<Avatar alt={msg['contact'].fullname} src={msg['contact'].avatar} />}
                        />)
                })}
            </Marquee>
            <Popper id={id} open={open} anchorEl={anchorEl} placement='top-end'>
                <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                    <Stepper activeStep={activeStep} nonLinear orientation="vertical">
                        <Step key='contact'>
                            <StepButton color="inherit" onClick={() => setActiveStep(0)}>
                                {'Contact Details'}
                            </StepButton>
                            <StepContent>
                                <Typography>{`Name: ${messages[activeIdx]['contact'].fullname}`}</Typography>
                                <Typography>{`Email: ${messages[activeIdx]['contact'].loginEmail}`}</Typography>
                                <Typography>{`Phone: ${messages[activeIdx]['contact'].internalPhoneNumber}`}</Typography>
                                <Typography>{`Title: ${messages[activeIdx]['contact'].jobTitle}`}</Typography>
                            </StepContent>
                        </Step>
                        <Step key='chat' disabled={true}>
                            <StepButton color="inherit" onClick={() => setActiveStep(1)}>
                                {'Start a chat (disabled)'}
                            </StepButton>
                            <StepContent>
                                <Typography>{'chat'}</Typography>
                            </StepContent>
                        </Step>
                        <Step key='call'>
                            <StepButton color="inherit" onClick={() => setActiveStep(2)}>
                                {'Start a call'}
                            </StepButton>
                            <StepContent>
                                <Typography>{`Last called: ${new Date().toLocaleString()}`}</Typography>
                                <Typography>{`Call status: calling`}</Typography>
                                <CallCard contacts={messages[activeIdx]['contact']?[messages[activeIdx]['contact']]:[]} />
                            </StepContent>
                        </Step>
                    </Stepper>
                </Box>
            </Popper>
        </div>
    )
}

