import React from "react";
import { AppBar, Box, Container, Grid, Paper, Typography } from '@mui/material';
import DefaultNavBar from "../../navbars/default/defaultNavBar";
import { rainbowService } from "../..";
import { AleMenu } from "../../components/menu/menu";
import { DefaultMessageTicker } from "../../footers/ticker/default";
import { Rainbow } from "../../rainbow/rainbow-core";

declare const VERSION: string;

const pages = ["About"];
const menus: AleMenu.Item[] = [
    {
        title: "Demo's", type: 'submenu', menuItems: [
            {
                title: "Rainbow CPAAS", type: 'menuheader', menuItems: [
                    {
                        title: "Virtual Workspace", type: "url",
                        data: { href: "https://vws.ale-euno.com/?token=x#/card-0", rel: 'noopener noreferrer', target: '_blank' }
                    },
                    {
                        title: "Guest Chat", type: "url",
                        data: { href: "https://guestchat.ale-euno.com/", rel: 'noopener noreferrer', target: '_blank' }
                    }
                ]
            }],
    },
    {
        title: "Verticals", type: 'submenu', menuItems: [
            {
                title: "Goverment", type: 'menuheader', menuItems: [
                    {
                        title: "Rainbow city", type: "url",
                        data: { href: "https://stuttgart1.ale-euno.com:4266/", rel: 'noopener noreferrer', target: '_blank' }
                    }
                ]
            },
            {
                title: "Education", type: 'menuheader', menuItems: [
                    {
                        title: "ALEA Classroom", type: "url",
                        data: { href: "https://demo-classroom.openrainbow.io/app/#/", rel: 'noopener noreferrer', target: '_blank' }
                    }
                ]
            }
        ]
    }
]

function Main() {
    const [doOpen, setDoOpen] = React.useState<boolean>(false);
    const [alemenu, setAlemenu] = React.useState(['Profile', 'Account', `${rainbowService.state.started ? 'Logout' : 'Login'}`]);

    React.useEffect(() => {
        rainbowService.onStarted.subscribe((started: boolean) => {
            setDoOpen(started);
            setAlemenu(['Profile', 'Account', `${started ? 'Logout' : 'Login'}`]);
        })
        Rainbow.IConversations.ONCHANGED.subscribe(data => {
            //console.log(`[MAIN] :: conversation onchange`, data);
            return;
            (async () => {
                if (doOpen) {
                    setDoOpen(!doOpen);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    setDoOpen(!doOpen);
                }
            })();
        })
    }, [])
    return (
        <>
            <Box
                display={'flex'} flexDirection={'column'} justifyContent={'center'} alignContent={'flex-start'}
                minHeight="100vh"
                width="100%"
                sx={{
                    backgroundImage: `url(${'./bg-main.jpg'})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    display: "grid",
                    placeItems: "start",
                }}
            >

                <Box justifySelf={'center'} width={'90%'}><DefaultNavBar menus={menus} pages={pages} aleMenu={alemenu} /></Box>
                <Container style={{ width: '100%' }}>
                    <Grid container item xs={12} md={7} alignItems={'start'} justifyContent="center" mx="auto">
                        <Typography
                            variant="h3"
                            color="white"
                            textAlign="center"
                            fontSize={{ xs: '30px', md: '40px' }}
                            mt={1}
                            mb={3}
                            px={{ xs: 6, lg: 12 }}
                        >
                            ALEA Demosite Portal
                        </Typography>
                        <Typography
                            variant="body1"
                            color="white"
                            textAlign="center"
                            px={{ xs: 6, lg: 12 }}
                            mt={0}
                        >
                            Built by the EMEA Platform Integration Team
                        </Typography>
                        <Typography
                            variant="body1"
                            color="white"
                            textAlign="center"
                            fontSize="13px"
                            px={{ xs: 6, lg: 12 }}
                            mt={0}
                        >
                            Version: {VERSION ? VERSION : 'Unknown'}
                        </Typography>
                    </Grid>
                </Container>


                <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
                    <Paper sx={{ backgroundColor: 'rgba(0,0,0,.5)' }}>{(doOpen || true) && <DefaultMessageTicker />}</Paper>
                </AppBar>


            </Box>

        </>
    )
}
export default Main;