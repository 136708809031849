
import { io, Socket } from 'socket.io-client';
import { Rainbow } from "./rainbow-core";
import { rainbowService } from '..';
import RecordRTC from 'recordrtc';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { WebsocketService } from '../services/websocket'
//import { GladiaWebsocketService } from '../services/websocket_gladia'
import to from 'await-to-js';
import { hystogramLine } from '../services/audioDetection';
import { slidingWindow } from '../services/utils'
import { GladiaTranscribeService, TranscribeResponse } from '../services/websocket-client-gladia';
const toWav = require('audiobuffer-to-wav')
//const { getWaveBlob } = require("webm-to-wav-converter");

class MediaStreamToWavBlob {
    audioContext = new AudioContext();
    mediaStreamSource: MediaStreamAudioSourceNode;
    audioData = [];
    blobStream: Subject<Blob> = new Subject();
    iv;
    scriptProcessorNode: ScriptProcessorNode = this.audioContext.createScriptProcessor(4096, 1, 1); // Buffer size, input channels, output channels
    constructor(mediaStream: MediaStream, public interval: number = 1000) {
        this.mediaStreamSource = this.audioContext.createMediaStreamSource(mediaStream);
        this.scriptProcessorNode.onaudioprocess = (event) => {
            const inputBuffer = event.inputBuffer;
            const channelData = inputBuffer.getChannelData(0); // Assuming mono audio
            this.audioData.push(new Float32Array(channelData));
            setInterval(() => { this.audioData = [] }, 5000)
        };
    }
    start() {
        this.scriptProcessorNode.connect(this.audioContext.destination);
        this.blobStream = new Subject();
        this.iv = setInterval(() => { this.blobStream.next(this.bufferToWavBlob()) }, this.interval)
    }
    stop() {
        this.scriptProcessorNode.disconnect()
        clearInterval(this.iv)
        this.blobStream.complete();
    }
    bufferToWavBlob(): Blob {
        const audioBuffer = this.createAudioBuffer();
        console.log("[RB-MEDIA] :: AudioBuffer created:", audioBuffer);
        let wav = toWav(audioBuffer)
        let wavblob: Blob = new Blob([new DataView(wav)], {
            type: 'audio/wav'
        })
        return wavblob;
    }

    // Function to create an AudioBuffer from captured audio data
    createAudioBuffer(context: AudioContext = this.audioContext, audioData = this.audioData): AudioBuffer {
        // Calculate the total length of the captured audio data
        const totalLength = audioData.reduce((acc, data: any) => acc + data.length, 0);
        // Create a new AudioBuffer with mono channel and a sample rate of 44100 Hz
        const audioBuffer = context.createBuffer(1, totalLength, context.sampleRate);
        // Copy the captured audio data into the AudioBuffer
        const channelData = audioBuffer.getChannelData(0);
        let offset = 0;
        audioData.forEach((data: any) => {
            channelData.set(data, offset);
            offset += data.length;
        });
        return audioBuffer;
    }
}

const AUDIOGAIN = 2.0; //minimum of about -3.4028235E38 and a max of about 3.4028235E38
const MINVOLUME = 0.003;

const GLADIA_API_KEY = "df5ae832-e2c8-47d2-91c4-5056edfd6d1e"
const gladiaWSSHost = "api.gladia.io/audio/text/audio-transcription"
const SAMPLE_RATE = 16000

export class RainbowAudioService {
    //audioSocket: Socket;
    onTTSresponseReceived: Subject<{ index: number, data: string }> = new Subject();
    transcriptionDisabled = false;
    translationDisabled = false;
    transcriptionEnabled = false;
    translationEnabled = false;
    audiomimetype = "audio/webm;codecs=opus";//"audio/wav" //"audio/webm;codecs=opus";
    optionsRecord = { mimeType: this.audiomimetype };
    textSent: boolean;
    recorderDistant;
    recorderLocal;
    mediaWavBlobLocal: MediaStreamToWavBlob;
    mediaWavBlobRemote: MediaStreamToWavBlob;
    recorder;
    wsServer: string = "localhost:3000"
    languageCode: 'nl' | 'en' = 'nl'
    audioSocketService: WebsocketService = new WebsocketService(this.wsServer)
    //gladiaSocketService: GladiaWebsocketService = new GladiaWebsocketService(gladiaWSSHost, GLADIA_API_KEY, 16000);
    //gladiaSocket: WebSocket;
    gladia: GladiaTranscribeService = new GladiaTranscribeService;
    onMediaData: ReplaySubject<{ volume: number }> = new ReplaySubject();
    localMediaStreamer;
    localAudioStream;
    remoteMediaStreamer;
    remoteAudioStream;

    constructor() {
        console.log("[RB-MEDIA] :: Creating Rainbow media service ");
        if (navigator.userAgent.indexOf("Firefox") !== -1) {
            this.audiomimetype = "audio/ogg;codecs=opus";
            this.optionsRecord = { mimeType: this.audiomimetype }
        } else {
            this.audiomimetype = "audio/webm;codecs=opus";
            this.optionsRecord = { mimeType: this.audiomimetype }
        }
        rainbowService.webRTC.onCallActive.subscribe(isActive => {
            console.warn("[RB-MEDIA] :: Call Status changed to ", isActive);
            if (isActive && this.transcriptionEnabled) {
                this.startRecorders();
                return;
            } else {
                setTimeout(() => {
                    if (this.transcriptionEnabled) {
                        this.stopRecorders();
                        this.audioSocketService.emit('stop', { type: 'data', data: "Stop voice stream" });
                    }
                }, 1000)
            }
        });
    }


    mediastreamToAudioBuffer(mediaStream: MediaStream): ReplaySubject<{ index: number, type: 'data' | 'volume', data: Blob | number, sourceIsLocal: boolean }> {
        let s: ReplaySubject<{ index: number, type: 'data' | 'volume', data: Blob | number, sourceIsLocal: boolean }> = new ReplaySubject();
        let volumeAverager: Subject<number> = new Subject<number>();
        let lastVolume = 0;
        let idx = 0, idx2 = 0;
        volumeAverager.pipe(slidingWindow(10)).subscribe(volumeArray => {
            const sum = volumeArray.reduce((a: number, b: number): number => a + b);
            let avg = sum / volumeArray.length;
            lastVolume = avg;
            //console.log(`sending last volume`, lastVolume)
            s.next({ index: idx, type: 'volume', data: avg, sourceIsLocal: true })
        });
        // Create an AudioContext
        (async () => {
            const audioContext = new AudioContext();
            s.subscribe(_ => { }, err => { }, () => {
                console.log(`[RB-MEDIA] :: disconnecting mediasource`)
                mediaStreamSource.disconnect();
                transcriber.disconnect();
                s.unsubscribe()
            })
            // Create a MediaStreamAudioSourceNode to capture audio from the MediaStream
            const mediaStreamSource = audioContext.createMediaStreamSource(mediaStream);
            var dst = audioContext.createMediaStreamDestination();
            var gainNode = audioContext.createGain();
            gainNode.gain.value = AUDIOGAIN;

            await audioContext.audioWorklet.addModule("/gladia.worklet.js")
            const transcriber = new AudioWorkletNode(
                audioContext,
                "gtranscriber.worklet"
            )
            mediaStreamSource.connect(gainNode)
            gainNode.connect(transcriber)
            //mediaStreamSource.connect(transcriber);


            // Initialize an array to store the captured audio data
            let audioData = new Map<number, any[]>();
            audioData.set(0, []);
            let count = 0;
            transcriber.port.onmessage = (e: {
                data: { buffer?: Float32Array, volume?: any }
            }) => {
                //console.log(`[RB-MEDIA] :: received audio channel data`, e.data)
                if (e.data.buffer) {
                    const audioBuffer: AudioBuffer = createAudioBuffer(audioContext, [e.data.buffer]);
                    if (audioBuffer) {
                        let wav = toWav(audioBuffer)
                        let wavblob: Blob = new Blob([new DataView(wav)], {
                            type: 'audio/wav'
                        })
                        if (wavblob && (wavblob)['size'] > 0 ) s.next({ index: ++count, type: 'data', data: wavblob, sourceIsLocal: true })
                    }
                }
                if (false && e.data.buffer) {
                    if (idx == 0) {
                        idx++;
                        if (lastVolume > MINVOLUME) {
                            if (idx2 == 0) {
                                audioData.set(idx2, [new Float32Array(e.data.buffer)])
                            } else {
                                let v = audioData.get(idx2 - 1); v.push(new Float32Array(e.data.buffer)); audioData.set(idx2, v)
                                audioData.set(idx2, v)
                            }
                        }
                    } else {
                        idx++;
                        if (lastVolume > MINVOLUME) {
                            let v = audioData.get(idx2); v.push(new Float32Array(e.data.buffer)); audioData.set(idx2, v)
                        }
                    }
                };
                if (e.data.volume) {
                    volumeAverager.next(e.data.volume)
                    //console.warn(`[RB-MEDIA] :: received audio volume `,e.data.volume, hystogramLine( e.data.volume))
                    //lastVolume = e.data.volume; //TODO detect a silence longer than eg 1 sec
                    //s.next({ index: idx, type: 'volume', data: e.data.volume, sourceIsLocal: true })
                    return
                }

                if (false && idx == 1) {
                    idx = 0;
                    const audioBuffer: AudioBuffer = createAudioBuffer(audioContext, audioData.get(idx2));
                    idx2++
                    audioData.set(idx2, []);
                    //console.log(`[RB-MEDIA] :: AudioBuffer ${idx2} with lastVolume ${lastVolume} created:`, audioBuffer);
                    if (audioBuffer) {
                        let wav = toWav(audioBuffer)
                        let wavblob: Blob = new Blob([new DataView(wav)], {
                            type: 'audio/wav'
                        })
                        if (wavblob && (wavblob)['size'] > 0 && lastVolume > 0.1) s.next({ index: ++count, type: 'data', data: wavblob, sourceIsLocal: true })
                    }
                    if (lastVolume < MINVOLUME) { console.log(`[RB-MEDIA] :: Clearing audiodata`); audioData = new Map<number, any[]>(); idx = 0; idx2 = 0; audioData.set(0, []); }
                }
            }

            // Set up event listener for the scriptProcessorNode's onaudioprocess event
            /*scriptProcessorNode.onaudioprocess = function (event) {
                // Get the input buffer from the event
                const inputBuffer = event.inputBuffer;
    
                // Copy the audio data from the input buffer
                const channelData = inputBuffer.getChannelData(0); // Assuming mono audio
                audioData.push(new Float32Array(channelData));
            };*/
            //setInterval(() => { console.log(`[RB-MEDIA] :: clearing audiobuffer`); audioData = [] }, 5000)

            // Start capturing audio from the MediaStream
            //scriptProcessorNode.connect(audioContext.destination);


            /*setInterval(() => {
                if (audioData.length==0) return;
                idx++
                const audioBuffer = createAudioBuffer(audioContext, audioData);
                console.log(`[RB-MEDIA] :: AudioBuffer ${idx} created:`, audioBuffer);
                let wav = toWav(audioBuffer)
                let wavblob: Blob = new Blob([new DataView(wav)], {
                    type: 'audio/wav'
                })
                if (wavblob && (wavblob)['size'] > 0) s.next({index: idx, type: 'data', data: wavblob, sourceIsLocal: true })
                    //if (this.audioSocket.connected) this.audioSocket.emit('audioData', {index: idx, type: 'data', data: wavblob, sourceIsLocal: true })
            }, 500);*/

            // Function to create an AudioBuffer from captured audio data
            function createAudioBuffer(context, audioData) {
                // Calculate the total length of the captured audio data
                if (!audioData || audioData.length == 0) { return null };
                const totalLength = audioData.reduce((acc, data) => acc + data.length, 0);

                // Create a new AudioBuffer with mono channel 
                const audioBuffer = context.createBuffer(1, totalLength, context.sampleRate);

                // Copy the captured audio data into the AudioBuffer
                const channelData = audioBuffer.getChannelData(0);
                let offset = 0;
                audioData.forEach(data => {
                    channelData.set(data, offset);
                    offset += data.length;
                });

                return audioBuffer;
            }
        })()
        return s;
    }


    startRecorders() {
        console.log("[RB-MEDIA] :: Starting recorders ...");
        let call = rainbowService.webRTC.call;
        console.log("[RB-MEDIA] :: Starting recorders, call:", call);
        var localRainbowStream = rainbowService.sdk.webRTC.getLocalStreamsFromCall(call.id)[0];
        var localAudioTracks = localRainbowStream.getAudioTracks();
        var localStream = new MediaStream(localAudioTracks);
        var remoteRainbowStream = rainbowService.sdk.webRTC.getRemoteStreamsFromCall(call.id)[0];
        var remoteAudioTracks = remoteRainbowStream.getAudioTracks();
        var distantStream = new MediaStream(remoteAudioTracks);
        
        this.localMediaStreamer = this.mediastreamToAudioBuffer(localStream);
        let that = this   
        that.gladia.result.subscribe((data:TranscribeResponse)=>{
            console.log(`${data.type}: Confidence:${data.confidence}, Inference: ${data.inference_time}, Duration: ${data.duration} :: ${data.transcription}`)
            this.onTTSresponseReceived.next({index: 0,data: data.confidence>0.2? data.transcription:"Waiting ..."})
        })     
        this.localMediaStreamer.subscribe(data => {
            if (data.type == 'data') {
                //this.audioSocketService.emit('audioData', data);
                that.gladia.socket.send(data.data)
                //console.warn(`[RB-MEDIA] :: sending audioData seq ${data.index}`)
            }
            if (data.type == 'volume') {
                //console.log(`Avg volume: ${data.data}`)
                this.onMediaData.next({ volume: data.data });
            }
        }, (err)=>{}, ()=>{
            localStream.getAudioTracks().forEach(s => {
                s.stop();
            });
        })
        console.warn("[RB-MEDIA] :: Recorders started");
    }

    stopRecorders() {
        console.log("[RB-MEDIA] :: Stopping recorders ...");
        if (this.localMediaStreamer) { this.localMediaStreamer.complete(); this.localMediaStreamer.unsubscribe() }
        if (this.remoteMediaStreamer) { this.remoteMediaStreamer.complete(); this.remoteMediaStreamer.unsubscribe() }
        if (this.localAudioStream) { this.localAudioStream.complete(); this.localAudioStream.unsubscribe() }

        console.warn("[RB-MEDIA] :: Recorders stopped");
    }

    loadSubscribers() {
        console.log("[RB-MEDIA] :: AudioServices webRTC listener added");
        rainbowService.webRTC.onCallStatusChange.subscribe(callStatus => {
            //let [call] = [...data];
            let call = rainbowService.webRTC.call;
            switch (call.status.value) {
                case "dialing":
                    console.log("[RB-MEDIA] :: Dialing ...");
                    if (this.transcriptionEnabled && this.audioSocketService.connected) {
                        console.log("[RB-MEDIA] :: Dialing, notifying TranscriptService to start ...");
                        this.audioSocketService.emit('start', { type: 'data', data: "About to send voice data ..." });
                    }
                    break;
                case "incommingCall":
                    /*
                    console.log("[ALE] :: Incoming call ...");
                    if (this.audioSocket && this.transcriptionEnabled) {
                      console.log("[ALE] :: Incoming call, notifying TranscriptService to start ...");
                      this.audioSocket.emit('start', {type: 'data', data: "About to send voice data ..."});
                    }
                    */
                    break;
                case "answering":
                    console.log("[RB-MEDIA] :: answering call ...");
                    if (this.transcriptionEnabled && this.audioSocketService.connected) {
                        console.log("[RB-MEDIA] :: Answering call, notifying TranscriptService to start ...");
                        this.audioSocketService.emit('start', { type: 'data', data: "About to send voice data ..." });
                    }

                    break;
                case "active":
                    break;
                case "Unknown":
                    if (this.transcriptionEnabled && this.audioSocketService.connected)
                        this.audioSocketService.emit('stop', { type: 'data', data: "Stopping Transcoding service" });
                    break;
                case "releasing":
                    break;
                default:
                    break;
            }
        });
        rainbowService.webRTC.onStreamAdded.subscribe((data) => {
            let [streams] = [...data];
            console.log("[RB-MEDIA] :: Stream added", streams);
        })
    }


    async onTranscriptionToggleChange(status: boolean): Promise<boolean> {

        this.transcriptionEnabled = status;

        if (status) {
            let [err, connected] = await to(this.audioSocketService.connect());
            console.log("[RB-MEDIA] :: websocket connect result", err, connected);
            if (!connected || err) return false;
            [err,connected] = await to(this.gladia.start());
            console.log(`[RB-MEDIA] :: Gladia start result`, err);
            if (err) return false;
            this.audioSocketService.onData.subscribe(
                (data: { index: number, data: string }) => {
                    console.log("[RB-MEDIA] :: STText message received", data);
                    this.onTTSresponseReceived.next(data)
                });
            this.audioSocketService.emit('languageCode', { type: 'data', data: this.languageCode });
            if (rainbowService.webRTC.callStatus == Rainbow.Call.Status.ACTIVE) {
                this.audioSocketService.emit('start', { type: 'data', data: "About to send voice data ..." });
                this.startRecorders();
            }
        } else {
            if (rainbowService.webRTC.callStatus == Rainbow.Call.Status.ACTIVE) this.stopRecorders();
            this.audioSocketService.emit('stop', { type: 'data', data: "Stop voice stream" });
            setTimeout(() => {
                this.audioSocketService.disconnect();
                this.gladia.stop();
                //this.gladiaSocketService.disconnect() 
                //this.gladiaSocket.close();
            }, 1000);
        }
        return (true)
    }
}

/***
 * archive code
 *     async convertWebmToWav(blob: Blob): Promise<Blob | boolean> {
        //const { getWaveBlob } = require("webm-to-wav-converter");
        //return (getWaveBlob(blob,false));
        //return Promise.resolve(blob)
        const audioContext = new AudioContext()
        const fileReader = new FileReader()
        fileReader.readAsArrayBuffer(blob)
        return new Promise((resolve, reject) => {
            fileReader.onloadend = () => {
                console.log(`[RB-MEDIA] :: convertWebmToWav - converting ...`);
                const arrayBuffer = fileReader.result
                audioContext.decodeAudioData((arrayBuffer as ArrayBuffer), (audioBuffer) => {
                    const wav = toWav(audioBuffer)
                    const blob = new Blob([new DataView(wav)], {
                        type: 'audio/wav'
                    })
                    console.log(`[RB-MEDIA] :: convertWebmToWav - return result`, blob);
                    resolve(blob);
                }, (err => {
                    console.error(`[RB-MEDIA] :: convertWebmToWav - error`, err);
                    resolve(false);
                }))
            }
        })
    }

    async createAudioBufferFromMediaStream(mediaStream) {
        // Create an OfflineAudioContext with the desired parameters
        const audioContext = new AudioContext()
        const offlineContext = new OfflineAudioContext({
            numberOfChannels: 2, // Stereo
            length: 44100 * 5, // 5 seconds buffer (adjust as needed)
            sampleRate: 44100 // Audio sample rate (Hz)
        });

        // Create a MediaStreamAudioSourceNode

        const sourceNode = audioContext.createMediaStreamSource(mediaStream);

        // Create an AudioBufferSourceNode
        const bufferSourceNode = offlineContext.createBufferSource();

        // Connect the MediaStreamAudioSourceNode to the AudioBufferSourceNode
        sourceNode.connect(bufferSourceNode);

        // Start the AudioBufferSourceNode
        bufferSourceNode.start();

        // Render the audio data from the MediaStream into an AudioBuffer
        const renderedBuffer = await offlineContext.startRendering();

        // Return the AudioBuffer
        return renderedBuffer;
    }

 */

/*
        const mediaStreamSource2 = audioContext.createMediaStreamSource(mediaStream);
        const distortion = audioContext.createWaveShaper();
        const gainNode = audioContext.createGain();
        const biquadFilter = audioContext.createBiquadFilter();
        const convolver = audioContext.createConvolver();
        const analyser = audioContext.createAnalyser();
        //const echoDelay = createEchoDelayEffect(audioContext);
        analyser.minDecibels = -90;
        analyser.maxDecibels = -10;
        analyser.smoothingTimeConstant = 0.85;
        mediaStreamSource2.connect(distortion);
        distortion.connect(biquadFilter);
        biquadFilter.connect(gainNode);
        convolver.connect(gainNode);
        //echoDelay.placeBetween(gainNode, analyser);
        analyser.connect(audioContext.destination);
        analyser.addEventListener
        */

         /*this.remoteMediaStreamer = this.mediastreamToAudioBuffer(distantStream);
        this.remoteMediaStreamer.subscribe(data => { 
            if (data.type=='data') this.audioSocketService.emit('audioData', data) 
            if (data.type=='volume') {
                this.onMediaData.next({volume: data.data})
            }
        })*/
        /*
        (async ()=>{
            let audioStream = await navigator.mediaDevices.getUserMedia({
                audio: 'default' ? { deviceId: { exact: 'default' } } : true
            });
            //this.gladia.transcribe(audioStream)
        })()
        */

        /*if (this.gladiaSocket.readyState == 1) {
            this.gladiaTranscribe(localStream);
        } else console.warn(`[RB-MEDIA] :: No WS to Gladia`)
        */



        //this.localAudioStream = audioStream(localStream);
        //this.localAudioStream.subscribe(()=>{})
        /*
        this.mediaWavBlobLocal = new MediaStreamToWavBlob(localStream);
        this.mediaWavBlobLocal.start();
        this.mediaWavBlobLocal.blobStream.subscribe(blob => {
            if (blob && (blob)['size'] > 0)
                if (this.audioSocket.connected) this.audioSocket.emit('audioData', { type: 'data', data: blob, sourceIsLocal: true })
        }, err=>{}, ()=>{console.log(`[RB-MEDIA] :: LocalStream has ended`)})
        
        this.mediaWavBlobRemote = new MediaStreamToWavBlob(distantStream);    
        this.mediaWavBlobRemote.start();
        this.mediaWavBlobRemote.blobStream.subscribe(blob => {
            if (blob && (blob)['size'] > 0)
                if (this.audioSocket.connected) this.audioSocket.emit('audioData', { type: 'data', data: blob, sourceIsLocal: false })
        }, err=>{}, ()=>{console.log(`[RB-MEDIA] :: RemoteStream has ended`)})
        */

        /*this.recorderDistant = new RecordRTC(distantStream, {
            //recorderType: RecordRTC.MediaStreamRecorder,
            mimeType: this.audiomimetype,
            timeSlice: 2000,
            // getNativeBlob: true,
            ondataavailable: (blob) => {
                console.warn("[RB-MEDIA] :: Remote Audio Record data available", JSON.stringify(blob));
                (async () => {
                    let wavblob = await this.convertWebmToWav(blob);
                    if (wavblob && (wavblob)['size'] > 0)
                        if (this.audioSocket.connected) this.audioSocket.emit('audioData', { type: 'data', data: wavblob, sourceIsLocal: false })
                })();
            }
        });
        //this.recorderDistant.startRecording();
        this.recorderLocal = new RecordRTC(localStream, {
            //recorderType: RecordRTC.MediaStreamRecorder,
            mimeType: "audio/webm",//this.audiomimetype,
            timeSlice: 1000,
            sampleRate: SAMPLE_RATE,
            desiredSampRate: SAMPLE_RATE,
            numberOfAudioChannels: 1,

            // getNativeBlob: true,
            ondataavailable: (blob: any) => { //Blob
                //console.warn("[RB-MEDIA] :: Local Audio Record data available", JSON.stringify(blob));
                
                (async () => {
    
                    let wavblob:any = await this.convertWebmToWav(blob);
                    if (wavblob && (wavblob)['size'] > 0) {
                        console.log("[RB-MEDIA] :: sending blob", wavblob);
                        this.gladia.socket.send(wavblob);
                    }
                        //if (this.audioSocket.connected) this.audioSocket.emit('audioData', { type: 'data', data: wavblob, sourceIsLocal: true })
                })();
            }
        });
        this.recorderLocal.startRecording();
        */


        /*
    deferredPromise() {
        const deferred: any = {};
        deferred.promise = new Promise((resolve, reject) => {
            deferred.resolve = resolve;
            deferred.reject = reject;
        });
        return deferred;
    }
    async startGladiaTranscriber() {
        try {
            const socketPromise = this.deferredPromise();

            // Initializes the websocket
            this.gladiaSocket = new WebSocket(
                'wss://api.gladia.io/audio/text/audio-transcription'
            );
            this.gladiaSocket.onopen = () => {
                // Check https://docs.gladia.io/reference/live-audio for more information about the parameters
                const configuration = {
                    x_gladia_key: GLADIA_API_KEY,
                    frames_format: 'bytes',
                    language_behaviour: 'automatic single language',
                    sample_rate: SAMPLE_RATE
                };
                console.log(`[RB-MEDIA] :: gladia -> Sending configuration`);
                this.gladiaSocket.send(JSON.stringify(configuration));
            };
            this.gladiaSocket.onerror = () => {
                socketPromise.reject(new Error(`[RB-MEDIA] :: gladia -> Couldn't connect to the server`));
            };
            this.gladiaSocket.onclose = (event) => {
                socketPromise.reject(
                    new Error(
                        `[RB-MEDIA] :: gladia -> Server refuses the connection: [${event.code}] ${event.reason}`
                    )
                );
            };
            this.gladiaSocket.onmessage = (event) => {
                let data;
                try {
                    data = JSON.parse(event.data);
                } catch (err) {
                    socketPromise.reject(
                        new Error(`[RB-MEDIA] :: gladia -> Cannot parse the message: ${event.data}`)
                    );
                }

                if (data?.event === 'connected') {
                    socketPromise.resolve(true);
                } else {
                    socketPromise.reject(
                        new Error(`[RB-MEDIA] :: gladia -> Server sent an unexpected message: ${event.data}`)
                    );
                }
            };
            await socketPromise.promise;
        } catch (err) {
            console.error(`[RB-MEDIA] :: gladia -> `, err);
            return;
        }
    }

    
    async startGladiaTranscriber() {
        const TYPE_KEY = "type";
        const TRANSCRIPTION_KEY = "transcription";
        const LANGUAGE_KEY = "language";
        this.gladiaSocket = new WebSocketClient(
            `wss://${gladiaWSSHost}`,
            {
                encoding:'WAV',
                x_gladia_key: GLADIA_API_KEY,
                language_behaviour: "automatic multiple languages",
                frames_format: "bytes",
                // "model_type":"accurate" <- Slower but more accurate model, useful if you need precise addresses for example.
            },
            {
                onTranscript(message) {
                    if (message.transcription) {
                        console.log(
                            `[RB-MEDIA] :: ${message[TYPE_KEY]}: (${message[LANGUAGE_KEY]}) ${message[TRANSCRIPTION_KEY]}`
                        );
                    }
                },
                onError(error) {
                    if (error.closed) {
                        console.error(`[RB-MEDIA] :: Gladia-Connection lost to the server, can't recover`, error);
                        //exit(1);
                    } else {
                        console.error(`[RB-MEDIA] :: Gladia WS error`,error);
                    }
                },
            }
        );
        await this.gladiaSocket.ready();
    }

    async gladiaTranscribe(mediaStream: MediaStream) {
        const audioContext = new AudioContext();
        const mediaStreamSource = audioContext.createMediaStreamSource(mediaStream);
        var dst = audioContext.createMediaStreamDestination();
        var gainNode = audioContext.createGain();
        gainNode.gain.value = AUDIOGAIN;

        await audioContext.audioWorklet.addModule("/gladia.worklet.js")
        const transcriber = new AudioWorkletNode(
            audioContext,
            "gtranscriber.worklet"
        )
        mediaStreamSource.connect(transcriber)
        //gainNode.connect(transcriber)
        transcriber.port.onmessage = (e: {
            data: { buffer?: Float32Array, volume?: any }
        }) => {
            //if (this.gladiaSocketService.connected && e.data.buffer) {
            if (this.gladiaSocket.readyState == 1 && e.data.buffer) {
                const audioBuffer: AudioBuffer = createAudioBuffer(audioContext, [e.data.buffer]);
                if (audioBuffer) {
                    let wav = toWav(audioBuffer)
                    let wavblob: Blob = new Blob([new DataView(wav)], {
                        type: 'audio/wav'
                    })
                    if (wavblob && (wavblob)['size'] > 0) {
                        //this.gladiaSocketService.send(JSON.stringify({ frames: audioBuffer }))
                        this.gladiaSocket.send(wavblob)
                    } else { console.warn(`[RB-MEDIA] :: gladiaTranscribe no data send to ws`, wavblob) }
                }
            } else {
                if (this.gladiaSocket.readyState != 1) console.log("[RB-MEDIA] :: gladia-WebSocket not connected")
                //if (!this.gladiaSocketService.connected) console.log("[RB-MEDIA] :: gladia-WebSocket not connected")
            }
        }
        this.gladiaSocket.onmessage = (event) => {
            let data;
            try {
                data = JSON.parse(event.data);
                console.log(`[RB-MEDIA] :: gladia -> message:`, data)
            } catch (err) {
                console.error(`[RB-MEDIA] :: gladia -> Cannot parse the message: ${event.data}`)
            };

        }
        function createAudioBuffer(context, audioData) {
            // Calculate the total length of the captured audio data
            if (!audioData || audioData.length == 0) { return null };
            try {
                const totalLength = audioData.reduce((acc, data) => acc + data.length, 0);
                // Create a new AudioBuffer with mono channel 
                const audioBuffer = context.createBuffer(1, totalLength, context.sampleRate);
                // Copy the captured audio data into the AudioBuffer
                const channelData = audioBuffer.getChannelData(0);
                let offset = 0;
                audioData.forEach(data => {
                    channelData.set(data, offset);
                    offset += data.length;
                });
                return audioBuffer;
            } catch (err) { console.error(`[RB-MEDIA] :: error in createAudioBuffer`, err, audioData); return null; }
        }
    }

    async convertWebmToWav(blob: Blob): Promise<Blob | boolean> {
        //const { getWaveBlob } = require("webm-to-wav-converter");
        //return (getWaveBlob(blob,false));
        //return Promise.resolve(blob)
        const audioContext = new AudioContext()
        const fileReader = new FileReader()
        fileReader.readAsArrayBuffer(blob)
        return new Promise((resolve, reject) => {
            fileReader.onloadend = () => {
                console.log(`[RB-MEDIA] :: convertWebmToWav - converting ...`);
                const arrayBuffer = fileReader.result
                audioContext.decodeAudioData((arrayBuffer as ArrayBuffer), (audioBuffer) => {
                    const wav = toWav(audioBuffer)
                    const blob = new Blob([new DataView(wav)], {
                        type: 'audio/wav'
                    })
                    console.log(`[RB-MEDIA] :: convertWebmToWav - return result`, blob);
                    resolve(blob);
                }, (err => {
                    console.error(`[RB-MEDIA] :: convertWebmToWav - error`, err);
                    resolve(false);
                }))
            }
        })
    }
*/
   
        //if (this.mediaWavBlobLocal) this.mediaWavBlobLocal.stop();
        //if (this.mediaWavBlobRemote) this.mediaWavBlobRemote.stop();

        /*
        if (this.recorderDistant) {
            this.recorderDistant.stopRecording();
            this.recorderDistant.destroy();
            this.recorderDistant = null;
        }
        if (this.recorderLocal) {
            this.recorderLocal.stopRecording();
            this.recorderLocal.destroy();
            this.recorderLocal = null;
        }
        if (this.recorder) {
            this.recorder.stopRecording();
            this.recorder.destroy();
            this.recorder = null;
        }
        */