import { io, Socket } from 'socket.io-client';
import { Observable, ReplaySubject, Subject, Subscription, fromEventPattern } from 'rxjs';

export class WebsocketService {
    private audioSocket: Socket;
    private onMessage: ReplaySubject<any>;
    onData: ReplaySubject<any> = new ReplaySubject();

    get connected():boolean {return this.audioSocket && this.audioSocket.connected}

    constructor(host: string) {
        console.log(`[WEBSOCKET] :: creating WebsocketService`)
        this.audioSocket = io(`wss://${host}`, {autoConnect:false});
        this.audioSocket.on('connect', ()=>{console.log(`[WEBSOCKET] :: connect`);})
    }
    connect(): Promise<boolean> {
        console.log(`[WEBSOCKET] :: Connecting to WebsocketService ...`)
        this.onMessage = new ReplaySubject();
        return new Promise<boolean>((resolve, reject) => {
            let wd = setTimeout(() => { console.log(`[WEBSOCKET] :: connection timeout`); resolve(true) }, 5000)
            this.audioSocket.once('connect', () => { clearTimeout(wd); resolve(true) });
            this.audioSocket.once('connect_error', (err) => { clearTimeout(wd); reject(err) })
            fromEventPattern(handler => this.audioSocket.onAny(handler), handler => this.audioSocket.offAny(handler), ((event, ...args) => {console.log(`[WEBSOCKET] :: received event in pattern`, event, args); return { event: event, args: args } })).subscribe(this.onMessage)
            this.onMessage.subscribe(
                data => {
                    console.log(`[WEBSOCKET] :: received event`, data);
                    switch (data.event) {
                        case 'admin':
                            console.log(`[WEBSOCKET] :: onMessage admin received`, data.args[0]);
                            break;
                        case 'message':
                            this.onData.next(data.args[0])
                            break;
                        case 'default':
                            console.log(`[WEBSOCKET] :: onMessage unknown event ${data.event} received`, data.args[0]);
                    }
                }
                , err => { }
                , () => { console.log(`[WEBSOCKET] :: onMessage completed`); this.audioSocket.disconnect() })
            this.audioSocket.connect()
            console.log(`[WEBSOCKET] :: num all-listeners`, this.audioSocket.listenersAny.length)
        });
    }
    
    disconnect() {
        if (this.onMessage) {
        this.onMessage.complete()
        this.onMessage.unsubscribe();
        console.log(`[WEBSOCKET] :: unsubscribe completed`)
        }
    }
    emit(ev: string, data: any) {
        if (this.audioSocket.connected) this.audioSocket.emit(ev,data)
    }

}