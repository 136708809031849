import { InfoOutlined } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { SnackbarContent, CustomContentProps } from 'notistack'
import React from 'react'

export interface PwaMessageProps extends CustomContentProps {
  
}

export const PwaMessage = React.forwardRef<HTMLDivElement, PwaMessageProps>((props, ref) => {
  const {
    // You have access to notistack props and options 👇🏼
    id,
    message,
    ...other
  } = props
  //console.log(`PwaMessage: ${message}`);
  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <Chip icon={<InfoOutlined />} label={message} color='primary' />
    </SnackbarContent>
  )
})