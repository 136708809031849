import { OperatorFunction, scan } from "rxjs";

export function objectsHaveSameKeys(...objects) {
    const allKeys = objects.reduce((keys, object) => keys.concat(Object.keys(object)), []);
    const union = new Set(allKeys);
    return objects.every(object => union.size === Object.keys(object).length);
}

export function slidingWindow<T>(size: number): OperatorFunction<T, T[]> {
    return scan((acc, curr) => [curr, ...acc].slice(0, size), []);
  }
  